import React from 'react';
import './playerCard.css';
import defaultCharacterImage from '../../../assets/images/p2.webp'; // Placeholder image path

function PlayerCard({ health, mana, imageUrl = defaultCharacterImage }) {
    return (
        <div className="PlayerCard">
            <img src={imageUrl} alt="Character" />
            <div className="bar-container">

                <div className="mana-bar" style={{ width: `${mana}%` }}></div>
                <div className="health-bar" style={{ width: `${health}%` }}></div>
            </div>
        </div>
    );
}

export default PlayerCard;

import React from 'react';
import './mainGameView.css';
// Adjust the path according to where you place your MainGameView.js
import gameViewImage from '../../assets/images/gameview.webp';
import Map from './Map'; // Adjust the import path as necessary


function MainGameView() {
    return (
        <div className="MainGameView">
            <div className='MainGameViewContent'>
                <Map />
                <div className='GameScreen' style={{ backgroundImage: `url(${gameViewImage})` }}>
                    <div className='TextOutput'>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                            sed do eiusmod tempor incididunt ut labore et dolore magna
                            aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                            ullamco laboris nisi ut aliquip ex ea commodo consequat.  </p>
                    </div>

                </div>
                <div className='TextOutput'>
                    {/* Your game text output here */}
                </div>
                {/* Text input area */}
                <div className='TextInput'>
                    <input type="text" placeholder="Type your action..." />
                    <button type="button">Send</button>

                </div>
            </div>
        </div >
    );
}

export default MainGameView;

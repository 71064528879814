import React from 'react';
import './sidebar.css';

function Sidebar() {
    return (
        <div className="Sidebar">
            <div className="Tabs">
                <div className="Tab">Tab 1</div>
                <div className="Tab">Tab 2</div>
                <div className="Tab">Tab 3</div>
                <div className="Tab">Tab 4</div>
                {/* ... other tabs if any ... */}
            </div>
            <div className="ChatFeed">
                {/* Chat messages will go here */}
            </div>
            <div className="ChatInput">
                <input type="text" placeholder="Type a message..." />
                <button type="button">Send</button>
            </div>
        </div>
    );
}


export default Sidebar;

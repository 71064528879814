import React, { useState } from 'react';
import PlayerCard from './PlayerCard/PlayerCard';
import './playerPanel.css';

function PlayerPanel() {
    // State to hold the list of players
    const [players, setPlayers] = useState([
        { id: 1, name: 'CosmicCoder32', characterClass: 'Wizard' },
        { id: 2, name: 'WhisperingWillow', characterClass: 'Rogue' },
        // More player objects can be added here
    ]);
    // Function to add a new player
    const addNewPlayer = () => {
        const newPlayer = {
            id: players.length + 1, // This is a simplistic approach; for real applications, consider using a unique ID generator
            name: `New Player ${players.length + 1}`,
            characterClass: 'Unknown', // Placeholder class
            health: 100, // Placeholder health
            mana: 100, // Placeholder mana
            // Add imageUrl if you have a default player image
        };
        setPlayers([...players, newPlayer]);
    };

    return (
        <div>
            <div className="PlayerPanel">
                {players.map(player => (
                    <PlayerCard key={player.id} name={player.name} characterClass={player.characterClass} />
                ))}
            </div>
            <button className="AddPlayerButton" onClick={addNewPlayer}>+</button>
        </div>
    );
}


export default PlayerPanel;

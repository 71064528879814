import React from 'react';
import './App.css';
import MainGameView from '../components/MainGameView/MainGameView';
import Sidebar from '../components/Sidebar/Sidebar';
import PlayerPanel from '../components/PlayerPanel/PlayerPanel';
import ProfilePic from './ProfilePic'; // Adjust the import path as necessary


function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>DunGEN</h1><span>v0.0</span>
      </header>
      <ProfilePic />
      <MainGameView />
      <Sidebar />
      <PlayerPanel />
    </div>
  );
}

export default App;

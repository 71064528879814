import React from 'react';
import './ProfilePic.css'; // Make sure to create this CSS file
import profilePic from '../assets/images/p5.webp';


function ProfilePic({ imageUrl = profilePic }) {
    return (
        <div className="ProfilePic">
            {/* Replace 'path_to_profile_image.jpg' with the path to your image */}
            <img src={imageUrl} alt="Profile" />
        </div>
    );
}

export default ProfilePic;

import React from 'react';
import mapImage from '../../assets/images/map.jpg'; // Adjust the relative path as necessary
import './Map.css'; // Ensure you have this CSS file to style the map

function Map() {
    return (
        <div className="Map">
            <img src={mapImage} alt="Game Map" />
        </div>
    );
}

export default Map;
